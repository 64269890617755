    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    :root {
        --primaryColor: #af9a7d;
        --mainWhite: #fff;
        --offWhite: #f7f7f7;
        --mainBlack: #222;
        --mainGrey: #ececec;
        --darkGrey: #cfcfcf;
        --mainTransition: all 0.3s linear;
        --mainSpacing: 3px;
        --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
        --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
    }
    /* globals */
    body {
        width: 100%;
        padding-top: 66px;
        color: var(--mainBlack);
        background: var(--mainWhite);
        font-family: -apple-system,
                    BlinkMacSystemFont,
                    "Segoe UI",
                    "Roboto",
                    "Oxygen",
                    "Ubuntu",
                    "Cantarell",
                    "Fira Sans",
                    "Droid Sans",
                    "Helvetica Neue",
                    sans-serif;
        line-height: 1.4;
    }
    h1 {
        font-size: 3em;
        line-height: 1;
        margin-bottom: 0.5em;
    }
    h2 {
        font-size: 2em;
        margin-bottom: 0.75em;
    }
    h3 {
        font-size: 1.5em;
        line-height: 1;
        margin-bottom: 1em;
    }
    h4 {
        font-size: 1.2em;
        line-height: 1.25;
        margin-bottom: 1.25em;
    }
    h5 {
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 1.5em;
    }
    h6 {
        font-size: 1em;
        font-weight: bold;
        margin-bottom: 1.5em;
    }
    
    .btn-primary {
        display: inline-block;
        text-decoration: none;
        letter-spacing: var(--mainSpacing);
        color: var(--mainBlack);
        background: var(--primaryColor);
        padding: 0.4rem 0.9rem;
        border: 3px solid var(--primaryColor);
        transition: var(--mainTransition);
        text-transform: uppercase;
        cursor: pointer;
    }
    .btn-primary:hover {
        background: transparent;
        color: var(--primaryColor);
    }
    .loading {
        text-transform: capitalize;
        text-align: center;
        margin-top: 3rem;
    }
    .error {
        text-align: center;
        text-transform: uppercase;
        margin: 2rem 0;
    }
    .empty-search {
        text-align: center;
        text-transform: capitalize;
        margin: 2rem 0;
        padding: 1rem;
        letter-spacing: var(--mainSpacing);
    }
    /*navbar*/
    .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.75rem 2rem; /*rem unit thke its value from browser base font size*/
    background:var(--offWhite) ;
    z-index: 1; /*z-index negative go inside the page evrything become on it and opposite is the same*/
    }
    .nav-header {
    display: flex;
    justify-content: space-between;
    }
    .nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    }
    .nav-icon {
    font-size: 1.5rem;
    color: var(--primaryColor);
    }
    .nav-links {
    list-style-type: none;
    transition: var(--mainTransition);
    height: 0;
    overflow: hidden;
    
    }
    .nav-links a {
    display: block;
    text-decoration: none;
    color: var(--mainBlack);
    padding: 1rem 0;
    transition: var(--mainTransition);
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: var(--mainSpacing);
    }
    .nav-links a:hover {
        color: var(--primaryColor);
    }
    .show-nav {
        height: 100px;
    }
    @media screen and (min-width: 768px) { /* use Media Queries to put afunc like min-width mean is width is over than the value do {}*/
    .nav-btn {
        display: none;
    }
    .nav-center {
        max-width: 1170px;
        margin: 0 auto;
        display: flex;
    }
    .nav-links {
        height: auto;
        display: flex;
        margin-left: 4rem;
    }
    .nav-links a {
        margin: 0 1rem;
        padding: 0.5rem 0;
    }
    }
    /*end of navbar*/
    /* home */
.main-content{
    position: relative;
    display: flex;
    justify-content: center;
    }
.body-img{
    height:calc(100vh - 66px);
    display: flex;
    overflow: hidden;
    width: 100%;
    object-fit: cover;
}

.room-detailes{
    background-color:rgb(2, 2, 2,0.5);
    color:var(--offWhite);
    width:80% ;
    text-align: center;
    position: absolute;
    top:20vh;
    max-width: 800px;
    padding: 2rem;
    font-family:"Segoe UI",sans-serif;
}
.room-title{
font-size: 4rem;
font-weight: 650;
letter-spacing: var(--mainSpacing);
}
.line{
    width: 45%;
    margin:1em auto;
    height: 0.3em;
    background-color: var(--primaryColor);
    border: none;
    outline: none;
    max-width: 150px;
}
.room-ofers{
font-size: 1.1rem;
letter-spacing: var(--mainSpacing);

}
.our-rooms-btn{
font-size:1rem;
padding: .5rem 1rem;
margin: 1rem;
border: .2em solid var(--primaryColor);
background-color: var(--primaryColor);
}
.our-rooms-btn:hover{
    background-color: transparent;
    cursor: pointer;
}
.services{
    background-color: var(--darkGrey);
}
.head-name{
    padding-top: 40px;
    text-align: center;
    margin: 0;
}
.mini{
    width: 35%;
    max-width:100px ;
    margin-top: .8rem;
}
.service-container{
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    gap: 1em;
    padding-bottom: 2rem;
}
.service-one-container{
    display:flex;
    flex-direction: column;
    height: auto;
    flex-wrap: wrap;
    width: 16rem;
    margin: 10px auto ;
}
.service-name{
font-weight: 700;
letter-spacing: var(--mainSpacing);
}
@media screen and (max-width: 820px){
    .room-title{
        font-size: 1.8rem;
        }
}
.featured-room-container{
    width: 100%;
    padding: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    gap: 2rem;
    margin-top: 2rem;
}
.featured-room-one-container{
    position: relative;
    box-shadow: var(--lightShadow);
}
.featured-room-one-container:hover{
    box-shadow: var(--darkShadow);
    transition: var(--mainTransition);
}
.box-shadow{
    background-color:rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom:0;
    height: 100%;
    width: 100%;
    transition: var(--mainTransition);
    display: flex;
}
.card-btn-link{
    margin: auto;
}
.onscreen-btn{
font-size:1rem;
padding: .5rem 1rem;
border: .2em solid var(--primaryColor);
background-color: var(--primaryColor);
}
.onscreen-btn:hover{
cursor: pointer;
background-color: transparent;
color: var(--primaryColor);
}
.over{
    display: none;
}
.featured-room-price{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    color: var(--offWhite);
    padding: .5rem;
    opacity: .8;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
}
.featured-room-price>p{
    margin: 0;
    padding: 0;
}
.featured-room-price>span{
    font-size: .5rem;
    margin: 0;
    padding: 0;
}
.featured-room-image{
    width: 100%;
    height: 100%;
}
@media screen and (min-width:700px){
    .featured-room-image{
        width: 27vw;
    }
}
.featured-room-type{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color:var(--mainBlack) ;
    padding:.5rem;
    color: var(--offWhite);
    font-weight: 600;
    letter-spacing: var(--mainSpacing);
    font-size: 1rem;
}
/*end of home*/
/*footer*/
.footer-container{
    text-align: center;
    background-color: var(--mainBlack);
    margin-top: 30px;
    height: 130px;
    color: var(--offWhite);
    display: flex;
    flex-direction: column;
}
.footer-title{
    margin: auto;
    font-size: 5vmin;
}
.footer-copyright{
    margin: auto;
    font-size: 3vmin;
}
.footer-copyright>a{
    text-decoration: none;
    color: var(--offWhite);
}
.footer-copyright>a:hover{
    text-decoration: underline;
    text-decoration-color: red;
    cursor: pointer;
}
/*end of footer*/
/* our room */
.ourroom-img{
height:70vh;
width: 100%;
display: flex;
overflow: hidden;
object-fit: cover;
}
.our-room{
    width:50% ;
    top:15vh;
}
.our-room-title{
font-size: 4rem;
font-weight: 650;
letter-spacing: var(--mainSpacing);
}
.our-room-ofers{
font-size: 1.1rem;
letter-spacing: var(--mainSpacing);
}
@media screen and (max-width:900px) {
    .our-room{
        width:80% ;
    }
    .room-width{
        font-size: 3rem;
    }
}
.form-container{
    display: flex;
    width: 90%;
    justify-content: center;
    margin: auto;
    flex-wrap: wrap;
    padding-top: 40px;
    gap: 40px;
}
.select-1-container{
    width: 30%;
}
.select-box{
    width: 100%;
}
.select-2-container{
    width: 30%;
}
.select-box{
    width: 100%;
}
.check-boxes-container{
    display: flex;
    flex-direction: column;
    width: 20%;
    align-content: center;
}
@media screen and (max-width:700px) {
    .select-1-container{
        width: 100%;
    }
    .select-2-container{
        width: 100%;
    }
    .room-price-container{
        width: 100%;
    }
    .check-boxes-container{
        width: 100%;
    }
}
/*end of our room*/
/*single room*/
    .single-room{
        width:100% ;
        top:15vh;
    }
    @media screen and (max-width:900px) {
        .single-room{
            width:80% ;
        }
        .single-room-width{
            font-size: 5vmin;
        }
        .single-offer{
            font-size: 3vmin;
        }
    }
    .single-room-ditailes-container{
        display: flex;
        width: 90%;
        flex-wrap: wrap;
        margin: 40px auto;
        gap: 40px;
    }
    .single-room-image{
        width:350px;
        margin: auto;
    }
    @media screen and (max-width:700px) {
        .single-room-image{
            width:85%;
        }
    }
    .single-room-d-i-container{
        display: flex;
        width: 90%;
        flex-wrap: wrap;
        margin: 40px auto;
        gap: 7vw;
    }
    .single-room-detail{
        width: 40vw;
        margin: auto;
    }
    .single-room-detail-h{
        letter-spacing: var(--mainSpacing);
    }
    .single-room-info{
        width: 40vw;
        margin: auto;
        margin-top: 0;
    }
    .single-room-info-h{
        letter-spacing: var(--mainSpacing);
    }
    .single-room-info-p{
        margin-top: 2rem;
        letter-spacing: var(--mainSpacing);
        font-weight: 200;
    }
    @media screen and (max-width:700px) {
        .single-room-detail{
            width: 85%;
        }
        .single-room-info{
            width: 85%;
        }
    }
    .extras{
        width: 90%;
        margin: auto;
    }
    .extras-h{
        letter-spacing: var(--mainSpacing);
    }
    .extras-p-container{
        display: flex;
        flex-wrap: wrap;
        margin: 40px auto;
        gap:2rem;
        
    }
    .extras-p{
        width: 28vw;
    }
    @media screen and (max-width:700px) {
        .extras{
            width:78%;
        }
        .extras-p{
            width: 78%;
        }
    }
/*end of single room*/
